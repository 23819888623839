<template>
  <div class="d-block">
    <v-form
      v-model="validForm"
      lazy-validation
      > 
      <div class="d-block w-100">
        <v-row>
          <v-col cols="2">
            <v-text-field
              v-model="invoiceForm"
              class=" "
              label="Invoice form:"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="invoiceSerial"
              class=" "
              label="Invoice serial:"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="toInvoiceNo"
              class=""
              label="Last invoice number:"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-select
              v-model="skip"
              :items="listSkip"
              label="Skip"
              class=""
              dense
            ></v-select>
            
          </v-col>
          <v-col cols="1">
            <v-btn 
              color="primary"
              class="item"
              small
              @click="emitData"
              >Get</v-btn>
          </v-col>
        </v-row>    
      </div>
    </v-form>
  </div>
</template>

<script>
const listSkip = [];

import InvoiceService from '@/services/invoice'
export default {
  name: 'SearchingListInvoiceBoxComponent',

  data: () => {
    return {
      validForm: false,
      listSkip,
      invoiceForm: '01GTKT0/001',
      invoiceSerial: 'AA/21E',// default CT: AA/20E
      skip: 20,
      fromInvoiceNo: null,
      toInvoiceNo: 0
    }
  },
  created() {
    let max = 30;
    for (let i =max; i >= 1; i--) {
      this.listSkip.push(i)
    }
  },
  mounted() {
    this.calculateFromInvoice()
    //this.emitData()
    this.loadMaxInvoiceNo()
  },
  methods: {
    calculateFromInvoice() {
      this.fromInvoiceNo = this.toInvoiceNo - this.skip;
    },

    emitData() {
      this.$emit('load-list-invoice', {
        invoiceForm: this.invoiceForm,
        invoiceSerial: this.invoiceSerial,
        fromInvoiceNo: parseInt(this.toInvoiceNo) - parseInt(this.skip),
        toInvoiceNo: parseInt(this.toInvoiceNo),
      })
    }, 

    async loadMaxInvoiceNo() {
      var maxInvoice = await InvoiceService.getMaxInvoiceNo(); 
      if (maxInvoice.error) {
        this.$toast.error('Can not get max Invoice Number');
        this.fromInvoiceNo = 0;
        return;
      }
      this.toInvoiceNo = maxInvoice;
      this.filterLimitSkip()
      this.emitData();
    },

    filterLimitSkip() {
      let filter = this.listSkip.filter(i => i < this.toInvoiceNo);
      if (filter.length) {
        this.listSkip = filter;
        // update skip
        this.skip = this.listSkip[0];
      }
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  margin-right: 10px;
}
.textWidth {
  width: 50px;
}
.selectWidth {
  width: 20px;
}
.w-25 {
  width: 25%;
}
.v-input {
  .v-messages {
    display: none;
  }
}

</style>