<template>
  <div class="d-block">
    <span class="text-subtitle-1 font-weight-black">TOTALS: <b class="primary--text">{{data.items.length}} records</b></span>
    <v-simple-table
      fixed-header
      class="mt-1"
      >
      <template v-slot:default>
        <tr>
          <th class="headerStyle" v-for="(itemTitle, indexList) in listTitle" :key="indexList">
            <template v-if="isHasDash(itemTitle.des)">
                <div class="d-flex w-100 flex-column text-center">
                  <span v-for="(title, indexTitle) in splitString(itemTitle.des)" :key="indexTitle" 
                    class="text-title-1 mt-1" 
                    v-bind:class="{
                      'font-italic': indexTitle == splitString(itemTitle.des).length - 1,
                    }"
                    >
                    {{ title}}
                  </span>
                </div>
            </template>
            <template v-else>
              {{ itemTitle.des }}
            </template>
          </th>    
        </tr>

        <template v-if="!data.loading">
          <tbody class="tbodyContent">
            <template v-if="data.items.length">
              <tr v-for="(itemObjInvoice, indexObjInvoice) in data.items" :key="indexObjInvoice">
                <td>
                  <div class="splitContent" 
                    v-bind:style="{color: findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') }">
                    <span class="first">{{ indexObjInvoice + 1 }}</span>
                  </div>
                </td>
                <!-- InvoiceForm/InvoiceSerial -->
                <td>
                  <div class="splitContent"
                    v-bind:style="{color: findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') }">
                    <span class="first">{{ itemObjInvoice.Invoice.InvoiceForm }}</span>
                    <span class="second">{{ itemObjInvoice.Invoice.InvoiceSerial }}</span>
                  </div>
                </td>
                <!-- InvoiceNo/InvoiceDate  -->
                <td>
                  <div class="splitContent"
                    v-bind:style="{color: findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') }">
                    <span class="first font-weight-bold">{{ `000${itemObjInvoice.Invoice.InvoiceNo}` }}</span>
                    <span class="second">{{  moment(itemObjInvoice.Invoice.InvoiceDate).format('DD/MM/YYYY') }}</span>
                  </div>
                </td>
                <!-- BuyerName/BuyerTaxCode -->
                <td>
                  <div class="splitContent text-left"
                    v-bind:style="{color: findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') }">
                    <span class="first">{{ itemObjInvoice.Invoice.BuyerName }}</span>
                    <span class="second">{{ itemObjInvoice.Invoice.BuyerTaxCode }}</span>
                  </div>
                </td>
                <!-- InvoiceTypeID -->
                <td>
                  <div class="splitContent"
                    v-bind:style="{color: findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') }">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <span class="font-weight-bold"
                          v-bind="attrs"
                          v-on="on">
                          {{ findItemInList(InvoiceConfig.PaymentMethod, 'id', itemObjInvoice.Invoice.InvoiceTypeID, 'name') }}
                        </span>
                      </template>
                      <span>{{ findItemInList(InvoiceConfig.PaymentMethod, 'id', itemObjInvoice.Invoice.InvoiceTypeID, 'text') }}</span>
                    </v-tooltip>
                  </div> 
                </td>
                <!-- Price/Tax -->
                <td>
                  <div class="splitContent text-right"
                    v-bind:style="{color: findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') }">
                    <span class="first">{{ amountPriceByColumn(itemObjInvoice.ListInvoiceDetailsWS, 'Price') }}</span>
                    <span class="second">{{ amountPriceByColumn(itemObjInvoice.ListInvoiceDetailsWS, 'DiscountAmount') }}</span>
                  </div>
                </td>
                <!-- Amount/TaxAmount -->
                <td>
                  <div class="splitContent text-right"
                    v-bind:style="{color: findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') }">
                    <span class="first font-weight-bold">{{ currencyFormat(itemObjInvoice.Invoice.SumPaymentAmount, 'end', '') }}</span>
                    <span class="second">{{ currencyFormat(itemObjInvoice.Invoice.SumTaxAmount, 'end', '') }}</span>
                  </div>
                </td>
                <!-- CurrencyID -->
                <td>
                  <div class="splitContent text-left"
                    v-bind:style="{color: findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') }">
                    <span class="first">{{ itemObjInvoice.Invoice.CurrencyID }}</span>
                  </div>
                </td>

                <!-- InvoiceDate -->
                <td>
                  <div class="splitContent"
                    v-bind:style="{color: findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') }">
                    <span class="first">{{ calculateTimming(itemObjInvoice.Invoice.SignedDate) }}</span>
                    <span class="second">{{ itemObjInvoice.Invoice.SellerTaxCode }}</span>
                  </div>
                </td>

                <!-- Note -->
                <td>
                  <div class="splitContent text-left"
                    v-bind:style="{color: findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') }">
                    <span class="first">{{ itemObjInvoice.Invoice.Note }}</span>
                  </div>
                </td>

                <!-- Status -->
                <td>
                  <div class="splitContent align-center">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <span class="font-weight-bold"
                          v-bind="attrs"
                          v-on="on">
                          <template v-if="findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color') != ''">
                            <v-icon small
                              :color="findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color')"
                            >
                              mdi-checkbox-blank-circle
                            </v-icon>
                          </template>
                          <template v-else>
                            <span class="first">
                              {{
                                getNameInnitial(findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'text'))
                              }}
                            </span>
                          </template>
                        </span>
                      </template>
                      <span> {{ findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'text') }}</span>
                    </v-tooltip>
                  </div>
                </td>

                <!-- Actions -->
                <td>
                  <div class="d-flex flex-row flex-wrap align-center justify-center">
                    <a :href="`/invoice/view-invoice-detail-by-invoice-guid/${itemObjInvoice.Invoice.InvoiceGUID}`">
                      <v-icon
                        style="cursor:pointer"
                        :color="findItemInList(InvoiceConfig.ListStatusInvoice, 'id', itemObjInvoice.Invoice.InvoiceStatusID, 'color')"
                        >
                      mdi-magnify
                      </v-icon>
                    </a>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="listTitle.length">
                  Data not found!
                </td>
              </tr>
            </template>
          
          </tbody> 
        </template> 

        <template v-else>
          <tbody>
            <tr>
              <td :colspan="listTitle.length" style="border: 1px solid #bdbdbd">
                <div class="d-flex justify-center align-center">
                  <v-skeleton-loader
                    :loading="data.loading"
                    class="mx-auto"
                    width="100%"
                    :type="`table-row-divider@${listTitle.length}`"
                  ></v-skeleton-loader>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import InvoiceService from '@/services/invoice'
import _ from 'lodash'
import moment from 'moment'
import InvoiceConfig from '../CreateInvoice/config'
import { currencyFormat, getNameInnitial } from '@/plugins/helper'
export default {
  name: "TableListInvoiceByFormAndSerial",
  data: () => {
    return {
      InvoiceConfig,
      listTitle: [
        {
          name: 'Order',
          des: 'STT'
        },
        {
          name: 'InvoiceForm/InvoiceSerial',
          des: 'Mẫu số/Ký hiệu'
        },
        {
          name: 'InvoiceNo/InvoiceDate',
          des: 'Số HĐ/Ngày HĐ'
        },
        {
          name: 'BuyerName/BuyerTaxCode',
          des: 'Tên người mua/Mã số thuế'
        },
        {
          name: 'InvoiceTypeID',
          des: 'HTTT'
        },
        {
          name: 'Price/DiscountAmount',
          des: 'Tiền hàng/Chiết khấu'
        },
        {
          name: 'Amount/TaxAmount',
          des: 'Tổng TT/Tiên thuế'
        },
        {
          name: 'CurrencyID',
          des: 'ĐV'
        },
        {
          name: 'InvoiceDate/SellerTaxCode',
          des: 'Ngày tạo/Người tạo'
        },
        {
          name: 'Note',
          des: 'Ghi chú'
        },
        {
          name: 'Status',
          des: 'Trạng thái'
        },
        {
          name: 'Actions',
          des: 'Hành động'
        }
      ],
      
      data: {
        loading: false,
        items: [],
        isOk: false,
        isError: false
      }
    }
  },
  methods: {
    moment,
    currencyFormat,
    getNameInnitial,
    isHasDash(stringInput) {
      return stringInput.includes('/');
    },  
    splitString(stringInput) {
      return stringInput.split('/')
    },
    async searchListInvoiceByFormAndSerial(commandObject) {
      this.data.loading = true;
      let resultList = await InvoiceService.getListInvoiceByFormAndSerial(commandObject);
      if (resultList.error) {
        this.$toast.error('Get List Invoice by Form & serial FAIL!')
        this.data.loading = false;
        return;
      }
      console.log(resultList)
      this.data = {
        loading: false,
        items: _.concat(this.data.items, _.orderBy(resultList.object, ['Invoice.InvoiceNo'], ['desc'])),
        isOk: resultList.isOk,
        isError: resultList.isError
      }
    },
    findItemInList(list, propFind, valueFind, returnProp) {
      return list.find(item => item[propFind] == valueFind)[returnProp];
    },
    amountPriceByColumn(listItem,  propGet) {
      let amount = 0;
      listItem.forEach(item => {
        amount = amount + item[propGet]
      })
      return this.currencyFormat(amount, 'end','');
    },

    calculateTimming(timming) {
      let date = this.moment(timming).format("YYYY-MM-DD");
      let today = this.moment().format('YYYY-MM-DD');
   
      let sameToday = moment(today).isSame(date, 'day');

      timming = new this.moment(timming);
      let current = new this.moment();
      var duration = moment.duration(current.diff(timming))
      if (sameToday) {
       
        let hour = duration._data.hours;
        let minutes = duration._data.minutes;
        return  `${hour} giờ ${minutes} phút trước`;
      } else {
        let days = duration.days();
        let weeks = duration.weeks();
        let months = duration.months();
        if (days == 0) {
          return `${duration.hours()} giờ trước `;
        } 
        if (days <= 7) {
          return `${days} ngày trước`;
        }
        if (days > 7) {
          if (weeks <= 4) {
            return `${weeks} tuần trước`;
          } else {
            return `${months} tháng trước`;
          }
        }
      }
      
    },

    redirectToDetailInvoice(invoiceGUID) {
      this.$router.push(`/invoice/view-invoice-detail-by-invoice-guid/${invoiceGUID}`)
    }
  }
}
</script>

<style scoped lang="scss">
.headerStyle { 
  background-color: #1967b2;
  color: #ffffff;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 9px 5px 9px 5px;
  border-right: 1px solid #bdbdbd;
}
.splitContent {
  display: flex;
  flex-direction: column;
  padding: 5px 2px 5px 2px;
  text-align: center;
  color: #1967b5;
  .first {
    font-style: normal;
  }
  .second {
    font-style: italic;
  }
}

.tbodyContent {
  td {
    border: 0.5px solid #bdbdbd;
  }
}
</style>