<template>
  <div class="d-block">
    <v-card elevation="0" outlined>
      <v-card-title>
        <span class="font-weight-black">
          List invoice by Invoice form and invoice serial   
        </span>
      </v-card-title>
      <v-card-text>
        <SearchingBox 
          class="mt-2"
          @load-list-invoice="loadListInvoice"
          />
        <TableContent 
          class="mt-1"
          ref="TableListInvoiceContent"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SearchingBox from './SearchingBox'
import TableContent from './TableContent'
export default {
  name: "ListInvoiceContainerComponent",
  components: {
    SearchingBox,
    TableContent
  },
  methods: {
    loadListInvoice(commandObject){
      this.$refs.TableListInvoiceContent.searchListInvoiceByFormAndSerial(commandObject)
    }
  }
}
</script>

<style>

</style>